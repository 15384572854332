<template>
  <div class="default-container">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'CyDefaultLayout',
}
</script>

<style lang="scss" scoped>
.default-container {
  flex: 1;
  align-self: stretch;
  justify-self: stretch;
  background-color: get-color("background");
}
</style>
